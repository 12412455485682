import Image from "next/image";
import router from "next/router";
import NotFoundImage from "assets/images/404.png";
import { AppContainer } from "container/app-container";
import { NonDashboardWrapper } from "container/non-dashboard-wrapper";
import { CustomButton, ErrorText, NotFoundWrapper } from "./styles";

export const Page404 = () => {
   return (
      <AppContainer title="Not Found Page">
         <NonDashboardWrapper>
            <NotFoundWrapper>
               <Image
                  src={NotFoundImage}
                  alt="not found"
                  width={400}
                  height={300}
                  layout="responsive"
               />
               <ErrorText>Hey, sorry we couln’t find the page you’re looking for.</ErrorText>
               <CustomButton
                  onClick={() => router.push("/")}
                  style={{
                     background: "#3578e5",
                     fontSize: "1.8rem",
                     width: "33rem",
                     height: "5rem",
                     opacity: 1,
                  }}
               >
                  Go Back Home
               </CustomButton>
            </NotFoundWrapper>
         </NonDashboardWrapper>
      </AppContainer>
   );
};
