import styled from "styled-components";

export const Header = styled.header`
   position: sticky;
   width: 100%;
   height: 10rem;
   background: #fff;
   display: flex;
   align-items: center;
   padding: 0 4rem;
   justify-content: space-between;
`;

export const HeaderLeft = styled.div`
   display: flex;
   gap: 2.5rem;
`;

export const HeaderRight = styled.div`
   display: flex;
   gap: 2.5rem;
`;
export const LogoWrapper = styled.div`
   display: block;
`;

export const ActionMenu = styled.div`
   display: flex;
   gap: 1.5rem;
`;

export const CustomButton = styled.button`
   width: 148px;
   height: 36px;
   border: none;
   color: #fff;
   opacity: 0.78;
   font-size: 12px;
   font-weight: 500;
   border-radius: 5px;
   cursor: pointer;
`;

export const NotFoundWrapper = styled.div`
   height: 150vh;
   display: flex;
   gap: 4rem;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background: #e5e5e5;
`;

export const ErrorText = styled.p`
   font-size: 1.8rem;
   line-height: 23px;
   color: #666666;
`;

export const SearchBox = styled.div`
   background: #ffffff;
   border: 1px solid #e5e5e5;
   box-sizing: border-box;
   border-radius: 5px;
   width: 35rem;
   height: 4.5rem;
   display: flex;
   align-items: center;
   gap: 1.5rem;
   padding: 0 1.5rem;

   & input {
      width: 30rem;
      border: none;
      height: 4rem;
      outline: none;
   }
`;

export const Footer = styled.div`
   margin-top: 4rem;
   padding: 4rem;
   display: grid;
   grid-template-columns: 45% 20% 20% 15%;
`;

export const SocialIcons = styled.div`
   display: flex;
   align-itens: center;
   gap: 2rem;
   margin-top: 2rem;
`;

export const CopyRight = styled.div`
   max-width: 200px;
   color: #1e2833;
   margin-top: 2rem;
   font-size: 1.5rem;
   font-weight: 500;
`;

export const FooterLinks = styled.ul`
   list-style: none;
   line-height: 1.5;
   color: #1e2833;
   margin: 0;
   padding: 0;

   & li.active {
      color: #3578e5;
      font-weight: 500;
   }

   & li {
      padding: 1rem 0;
      text-transform: capitalize;

      a {
         text-decoration: none;
         color: #1e2833;
      }
   }
`;
